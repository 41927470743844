import * as $ from 'jquery'
import slick from 'slick-carousel'



$('.slider__videoslider').slick({
  infinite: true,
  speed: 300,
  slidesToShow: 2,
  dots: true,
  prevArrow: $('.slider-container__controls .left'),
  nextArrow: $('.slider-container__controls .right'),

  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        dots: true,
        prevArrow: false,
        nextArrow: false
      }
    }
  ]

});