const navTrigger = document.querySelector('.nav__trigger')
const navWrapper = document.querySelector('.nav__wrapper')

function initNav() {
  navTrigger.addEventListener('click', toggleNav)
}

function toggleNav() {
  navWrapper.classList.toggle('js-nav-active');
}

if (navTrigger && navWrapper) {
  initNav()
}


// first subnav
$('.nav__main-link--1').hover(function() {
  $('.nav__subnav--first').fadeIn();
  $('.nav__subnav--second').fadeOut();
  $('.nav__subnav--third').fadeOut();
  $('.nav__subnav--fourth').fadeOut();
  $('.nav__subnav--fifth').fadeOut();
});

// second subnav
$('.nav__main-link--2').hover(function() {
  $('.nav__subnav--second').fadeIn();
  $('.nav__subnav--first').fadeOut();
  $('.nav__subnav--third').fadeOut();
  $('.nav__subnav--fourth').fadeOut();
  $('.nav__subnav--fifth').fadeOut();
});

// third subnav
$('.nav__main-link--3').hover(function() {
  $('.nav__subnav--third').fadeIn();
  $('.nav__subnav--first').fadeOut();
  $('.nav__subnav--second').fadeOut();
  $('.nav__subnav--fourth').fadeOut();
  $('.nav__subnav--fifth').fadeOut();
});

// fourth subnav
$('.nav__main-link--4').hover(function() {
  $('.nav__subnav--fourth').fadeIn();
  $('.nav__subnav--first').fadeOut();
  $('.nav__subnav--second').fadeOut();
  $('.nav__subnav--third').fadeOut();
  $('.nav__subnav--fifth').fadeOut();
});

// fifth subnav
$('.nav__main-link--5').hover(function() {
  $('.nav__subnav--fifth').fadeIn();
  $('.nav__subnav--first').fadeOut();
  $('.nav__subnav--second').fadeOut();
  $('.nav__subnav--third').fadeOut();
  $('.nav__subnav--fourth').fadeOut();
});

// fade out navs
$('.page').hover(function() {
  $('.nav__subnav--first').fadeOut();
  $('.nav__subnav--second').fadeOut();
  $('.nav__subnav--third').fadeOut();
  $('.nav__subnav--fourth').fadeOut();
  $('.nav__subnav--fifth').fadeOut();
});



// mobile nav
$('.nav__mobile-bar-item--menu').click(function() {
  $('.nav__mobile-subnav').fadeToggle();
  $('.nav__mobile-open').toggleClass('display-none');
  $('.nav__mobile-close').toggleClass('display-none');
});

$('.nav__main-link--6').hover(function(){
  $('.nav__subnav--first').fadeOut();
  $('.nav__subnav--second').fadeOut();
  $('.nav__subnav--third').fadeOut();
  $('.nav__subnav--fourth').fadeOut();
  $('.nav__subnav--fifth').fadeOut();
})


// jQuery(document).ready(function($){
//     $('div').on('click', function(e) {
//         if (e.target !== this) return;
//         alert('div clicked');
//     });
// });
