document.addEventListener('DOMContentLoaded', () => {
  const videos = document.querySelectorAll('.video-slide');

  videos.forEach(function(video) {
    const playBtn = video.nextElementSibling;
    const playImg = playBtn.querySelector('img');

    const baseUrl = `${window.location.protocol}//${window.location.host}`;

    playBtn.addEventListener('click', function() {
      if(video.paused) {
        video.play();
        playImg.src = `${baseUrl}/assets/img/svg/pausebutton.svg`;
        playImg.alt = 'Pause';
        playImg.style.transform = 'translateX(12%)';
        video.setAttribute('controls', 'controls');
      }
      else {
        video.pause();
        playImg.src = `${baseUrl}/assets/img/svg/playbutton.svg`;
        playImg.alt = 'Play';
        playImg.style.transform = 'translateX(20%)';
      }
   
    });

  });
});