

function toggleAccordion() {

  const accordionTitles = document.querySelectorAll('.accordion__title')

  if (accordionTitles) {

    for (let i = 0; i < accordionTitles.length; i++) {

      const accordionWrapper = accordionTitles[i].closest('.accordion__wrapper')
      const accordionContent = accordionWrapper.querySelector('.accordion__content')
      accordionWrapper.classList.add('js-ready')
      accordionWrapper.classList.add('js-collapsed')
      accordionContent.style.height = '0'
      accordionContent.style.overflowY = 'hidden'

      accordionTitles[i].addEventListener('click', function(e) {
        const targetWrapper = e.target.closest('.accordion__wrapper')
        const targetContent = targetWrapper.querySelector('.accordion__content')

        if (targetContent.style.height === '0px') {
          targetWrapper.classList.remove('js-collapsed')
          targetWrapper.classList.add('js-expanded')
          targetContent.style.height = targetContent.scrollHeight + 'px'
        } else {
          targetContent.style.height = '0'
          targetWrapper.classList.remove('js-expanded')
          targetWrapper.classList.add('js-collapsed')
        }
      })

    }

  }

}

// IE9+ polyfill
if (!Element.prototype.matches) {
  Element.prototype.matches =
    Element.prototype.msMatchesSelector ||
    Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
  Element.prototype.closest = function(s) {
    var el = this;

    do {
      if (Element.prototype.matches.call(el, s)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}


toggleAccordion()
