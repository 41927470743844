(() => {
  const logoOverlay = $('.logo-wall-trigger');

  if (!logoOverlay) {
    return;
  }


  const logoHoverContainer = $('.logo-hover-container');

  if (!logoHoverContainer) {
    return;
  }

  const closeLogoOverlay = logoHoverContainer
    .children('.logo-wall-hover-wrapper')
    .children('.overlay-close-icon')

  logoHoverContainer.on('click', (e) => {
    e.stopPropagation();
  });

  logoOverlay
    .on('click', (e) => {
      e.stopPropagation();

      let openOverlyEl = e.target.parentElement;
      let hoverContainerEl = openOverlyEl.querySelector('.logo-hover-container');
      toggleLogoOverlay(hoverContainerEl);
    });

  closeLogoOverlay.on('click', (e) => {
    const open = document.querySelector('.logo-hover-container.active');
    toggleLogoOverlay(open);
  });

  function toggleLogoOverlay(hoverContainerEl) {
    if (hoverContainerEl.classList.contains('active')) {
      hoverContainerEl.classList.remove('active');
      document.querySelector('body').classList.remove('no-scroll');
    } else {
      $('.logo-hover-container').removeClass('active');

      hoverContainerEl.classList.add('active');
      document.querySelector('body').classList.add('no-scroll');
    }
  }

  document.addEventListener('click', (e) => {
    const open = document.querySelector('.logo-hover-container.active');

    if (open && e.target !== open && !open.contains(e.target)) {
      open.classList.remove('active');
      document.querySelector('body').classList.remove('no-scroll');
    }
  }
  );
})();