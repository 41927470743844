// slick documentation: https://kenwheeler.github.io/slick/
import * as $ from 'jquery'
import slick from 'slick-carousel'

$('.slider__wrapper').each(function(key, item) {
  $(this).slick({
    infinite: true,
    speed: 300,
    draggable: true,
    adaptiveHeight: true,
    arrows: true,
    dots: false,
    slidesToShow: 3,
    centerPadding: '140px',
    prevArrow: $(this).parents('.slider').find('.slider__control--prev'),
    nextArrow: $(this).parents('.slider').find('.slider__control--next'),

    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          dots: true,
          prevArrow: false,
          nextArrow: false
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        }
      }
    ]

  });

  if (!($('.slider .slick-slide').length > 1)) {
    $('.slider__control--prev').hide();
    $('.slider__control--next').hide();
  }
});


$('.slider__homeslider').each(function(key, item) {
  $(this).slick({
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4500,
    speed: 800,
    draggable: true,
    adaptiveHeight: true,
    arrows: true,
    dots: true,
    slidesToShow: 1,
    prevArrow: $(this).parents('.slider').find('.slider__control--prev'),
    nextArrow: $(this).parents('.slider').find('.slider__control--next'),



  });

  if (!($('.slider .slick-slide').length > 1)) {
    $('.slider__control--prev').hide();
    $('.slider__control--next').hide();
  }
});
