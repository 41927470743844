const searchInput = document.querySelector(".search__input");
const searchTrigger = document.querySelectorAll(".js-search-trigger");
const searchForm = document.querySelector(".search__form");

function initSearch() {
  for (let i = 0; i < searchTrigger.length; i++) {
    const element = searchTrigger[i];
    element.addEventListener("click", function (e) {
      e.preventDefault();

      if (document.body.classList.contains("js-nav-active")) {
        document.body.classList.remove("js-nav-active");
      }

      if (document.body.classList.contains("js-search-active")) {
        searchActive = false;
        document.body.classList.remove("js-search-active");
      } else {
        searchActive = true;
        document.body.classList.add("js-search-active");
        searchInput.focus();
      }
    });
  }
}

function search() {
  const searchIcon = searchForm.querySelector(".icon__search");

  if (searchIcon) {
    searchIcon.addEventListener("click", function () {
      searchForm.submit();
    });
  }
}

if (searchTrigger && searchInput) {
  initSearch();
  search();
}
